import { useEffect } from "react"
import PropTypes from "prop-types"
// import { withPrefix } from "gatsby"
import { ThemeProvider } from "styled-components"

import { themeDefaultV2 as themeDefault } from "@fastly/consistently"
import { ConsistentlyProvider, PreFooter } from "@fastly/consistently-vanilla"
import { themeConsistentlyLightV3 } from "@fastly/consistently-vanilla/tokens"
import Loadable from "@loadable/component"

import Header from "./Header/Header"
import Footer from "./footer"
import RichText from "./rich-text/renderer"

import { FeatureFlagProvider } from "@hooks/use-feature-flag"
import useMedia from "@hooks/use-media"
import { Globals } from "@react-spring/web"
import { LocalizationProvider } from "./LocalizationProvider/LocalizationProvider"
import { text as text_cn, links as links_cn, preFooterWrapper } from "./PreFooter/PreFooter.module.css"

import colorShim from "!raw-loader!@assets/css/colors.shim.css"
import typographyShim from "!raw-loader!@assets/css/typography.shim.css"

const MountDetector =
  process.env.GATSBY_CYPRESS_ACTIVE_ENV === "development"
    ? Loadable(() => import("@components/MountDetector"))
    : () => null

const Layout = ({ children, pageContext: { navData, hidePreFooter, featureFlags } }) => {
  const locale = process.env.GATSBY_QUERY_LOCALE
  const prefersReducedMotion = useMedia("(prefers-reduced-motion: reduce)")

  useEffect(() => {
    Globals.assign({
      skipAnimation: prefersReducedMotion,
    })
  }, [prefersReducedMotion])

  return (
    <LocalizationProvider locale={locale}>
      <ConsistentlyProvider theme={themeConsistentlyLightV3}>
        <ThemeProvider theme={themeDefault}>
          <FeatureFlagProvider value={featureFlags}>
            <MountDetector />
            {navData ? <Header /> : null}
            <style>{colorShim}</style>
            <style>{typographyShim}</style>
            {children}
            {navData?.preFooterText?.rtContent && !hidePreFooter && (
              <div className={preFooterWrapper}>
                <PreFooter id="preFooter">
                  <div className={text_cn}>
                    <RichText body={navData.preFooterText.rtContent} mikan={navData.preFooterText.mikan} />
                  </div>
                  <div className={links_cn}>
                    <RichText body={navData.preFooterLinks.rtContent} mikan={navData.preFooterLinks.mikan} />
                  </div>
                </PreFooter>
              </div>
            )}
            {navData ? <Footer /> : null}
          </FeatureFlagProvider>
        </ThemeProvider>
      </ConsistentlyProvider>
    </LocalizationProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node,
}

export { Layout }
