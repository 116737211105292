/* GENERATED FILE */
import loadable from "@loadable/component"

const Arrow = (props) => {
  const Loaded = loadable(() => import("@fastly/consistently-icons/dist/icons/Arrow"))

  return <Loaded {...props} />
}

const BotA = (props) => {
  const Loaded = loadable(() => import("@fastly/consistently-icons/dist/icons/BotA"))

  return <Loaded {...props} />
}

const BotB = (props) => {
  const Loaded = loadable(() => import("@fastly/consistently-icons/dist/icons/BotB"))

  return <Loaded {...props} />
}

const Browser = (props) => {
  const Loaded = loadable(() => import("@fastly/consistently-icons/dist/icons/Browser"))

  return <Loaded {...props} />
}

const Cancel = (props) => {
  const Loaded = loadable(() => import("@fastly/consistently-icons/dist/icons/Cancel"))

  return <Loaded {...props} />
}

const Check = (props) => {
  const Loaded = loadable(() => import("@fastly/consistently-icons/dist/icons/Check"))

  return <Loaded {...props} />
}

const CheckboxCheck = (props) => {
  const Loaded = loadable(() => import("@fastly/consistently-icons/dist/icons/CheckboxCheck"))

  return <Loaded {...props} />
}

const CheckboxDash = (props) => {
  const Loaded = loadable(() => import("@fastly/consistently-icons/dist/icons/CheckboxDash"))

  return <Loaded {...props} />
}

const Chevron = (props) => {
  const Loaded = loadable(() => import("@fastly/consistently-icons/dist/icons/Chevron"))

  return <Loaded {...props} />
}

const Clear = (props) => {
  const Loaded = loadable(() => import("@fastly/consistently-icons/dist/icons/Clear"))

  return <Loaded {...props} />
}

const CloudMigration = (props) => {
  const Loaded = loadable(() => import("@fastly/consistently-icons/dist/icons/CloudMigration"))

  return <Loaded {...props} />
}

const ControlA = (props) => {
  const Loaded = loadable(() => import("@fastly/consistently-icons/dist/icons/ControlA"))

  return <Loaded {...props} />
}

const ControlB = (props) => {
  const Loaded = loadable(() => import("@fastly/consistently-icons/dist/icons/ControlB"))

  return <Loaded {...props} />
}

const Cycle = (props) => {
  const Loaded = loadable(() => import("@fastly/consistently-icons/dist/icons/Cycle"))

  return <Loaded {...props} />
}

const DDoSA = (props) => {
  const Loaded = loadable(() => import("@fastly/consistently-icons/dist/icons/DDoSA"))

  return <Loaded {...props} />
}

const DDoSB = (props) => {
  const Loaded = loadable(() => import("@fastly/consistently-icons/dist/icons/DDoSB"))

  return <Loaded {...props} />
}

const DevOps = (props) => {
  const Loaded = loadable(() => import("@fastly/consistently-icons/dist/icons/DevOps"))

  return <Loaded {...props} />
}

const Devices = (props) => {
  const Loaded = loadable(() => import("@fastly/consistently-icons/dist/icons/Devices"))

  return <Loaded {...props} />
}

const Docs = (props) => {
  const Loaded = loadable(() => import("@fastly/consistently-icons/dist/icons/Docs"))

  return <Loaded {...props} />
}

const Dollar = (props) => {
  const Loaded = loadable(() => import("@fastly/consistently-icons/dist/icons/Dollar"))

  return <Loaded {...props} />
}

const Download = (props) => {
  const Loaded = loadable(() => import("@fastly/consistently-icons/dist/icons/Download"))

  return <Loaded {...props} />
}

const Error = (props) => {
  const Loaded = loadable(() => import("@fastly/consistently-icons/dist/icons/Error"))

  return <Loaded {...props} />
}

const Failover = (props) => {
  const Loaded = loadable(() => import("@fastly/consistently-icons/dist/icons/Failover"))

  return <Loaded {...props} />
}

const FastlyBuilt = (props) => {
  const Loaded = loadable(() => import("@fastly/consistently-icons/dist/icons/FastlyBuilt"))

  return <Loaded {...props} />
}

const FastlyLogo = (props) => {
  const Loaded = loadable(() => import("@fastly/consistently-icons/dist/icons/FastlyLogo"))

  return <Loaded {...props} />
}

const GatedAccess = (props) => {
  const Loaded = loadable(() => import("@fastly/consistently-icons/dist/icons/GatedAccess"))

  return <Loaded {...props} />
}

const GlobeA = (props) => {
  const Loaded = loadable(() => import("@fastly/consistently-icons/dist/icons/GlobeA"))

  return <Loaded {...props} />
}

const GlobeB = (props) => {
  const Loaded = loadable(() => import("@fastly/consistently-icons/dist/icons/GlobeB"))

  return <Loaded {...props} />
}

const GlobeC = (props) => {
  const Loaded = loadable(() => import("@fastly/consistently-icons/dist/icons/GlobeC"))

  return <Loaded {...props} />
}

const IPblocking = (props) => {
  const Loaded = loadable(() => import("@fastly/consistently-icons/dist/icons/IPblocking"))

  return <Loaded {...props} />
}

const ImageOptimization = (props) => {
  const Loaded = loadable(() => import("@fastly/consistently-icons/dist/icons/ImageOptimization"))

  return <Loaded {...props} />
}

const Info = (props) => {
  const Loaded = loadable(() => import("@fastly/consistently-icons/dist/icons/Info"))

  return <Loaded {...props} />
}

const LanguageJavascript = (props) => {
  const Loaded = loadable(() => import("@fastly/consistently-icons/dist/icons/LanguageJavascript"))

  return <Loaded {...props} />
}

const LanguageRust = (props) => {
  const Loaded = loadable(() => import("@fastly/consistently-icons/dist/icons/LanguageRust"))

  return <Loaded {...props} />
}

const LanguageWebAssembly = (props) => {
  const Loaded = loadable(() => import("@fastly/consistently-icons/dist/icons/LanguageWebAssembly"))

  return <Loaded {...props} />
}

const Like = (props) => {
  const Loaded = loadable(() => import("@fastly/consistently-icons/dist/icons/Like"))

  return <Loaded {...props} />
}

const Link = (props) => {
  const Loaded = loadable(() => import("@fastly/consistently-icons/dist/icons/Link"))

  return <Loaded {...props} />
}

const ListA = (props) => {
  const Loaded = loadable(() => import("@fastly/consistently-icons/dist/icons/ListA"))

  return <Loaded {...props} />
}

const ListB = (props) => {
  const Loaded = loadable(() => import("@fastly/consistently-icons/dist/icons/ListB"))

  return <Loaded {...props} />
}

const ListC = (props) => {
  const Loaded = loadable(() => import("@fastly/consistently-icons/dist/icons/ListC"))

  return <Loaded {...props} />
}

const Location = (props) => {
  const Loaded = loadable(() => import("@fastly/consistently-icons/dist/icons/Location"))

  return <Loaded {...props} />
}

const LockA = (props) => {
  const Loaded = loadable(() => import("@fastly/consistently-icons/dist/icons/LockA"))

  return <Loaded {...props} />
}

const LockB = (props) => {
  const Loaded = loadable(() => import("@fastly/consistently-icons/dist/icons/LockB"))

  return <Loaded {...props} />
}

const Login = (props) => {
  const Loaded = loadable(() => import("@fastly/consistently-icons/dist/icons/Login"))

  return <Loaded {...props} />
}

const ModernWeb = (props) => {
  const Loaded = loadable(() => import("@fastly/consistently-icons/dist/icons/ModernWeb"))

  return <Loaded {...props} />
}

const NoEdit = (props) => {
  const Loaded = loadable(() => import("@fastly/consistently-icons/dist/icons/NoEdit"))

  return <Loaded {...props} />
}

const NonSni = (props) => {
  const Loaded = loadable(() => import("@fastly/consistently-icons/dist/icons/NonSni"))

  return <Loaded {...props} />
}

const PartnerBuilt = (props) => {
  const Loaded = loadable(() => import("@fastly/consistently-icons/dist/icons/PartnerBuilt"))

  return <Loaded {...props} />
}

const Partners = (props) => {
  const Loaded = loadable(() => import("@fastly/consistently-icons/dist/icons/Partners"))

  return <Loaded {...props} />
}

const Plus = (props) => {
  const Loaded = loadable(() => import("@fastly/consistently-icons/dist/icons/Plus"))

  return <Loaded {...props} />
}

const RateLimiting = (props) => {
  const Loaded = loadable(() => import("@fastly/consistently-icons/dist/icons/RateLimiting"))

  return <Loaded {...props} />
}

const ResourceAnalystReport = (props) => {
  const Loaded = loadable(() => import("@fastly/consistently-icons/dist/icons/ResourceAnalystReport"))

  return <Loaded {...props} />
}

const ResourceCaseStudy = (props) => {
  const Loaded = loadable(() => import("@fastly/consistently-icons/dist/icons/ResourceCaseStudy"))

  return <Loaded {...props} />
}

const ResourceDataSheet = (props) => {
  const Loaded = loadable(() => import("@fastly/consistently-icons/dist/icons/ResourceDataSheet"))

  return <Loaded {...props} />
}

const ResourceEbook = (props) => {
  const Loaded = loadable(() => import("@fastly/consistently-icons/dist/icons/ResourceEbook"))

  return <Loaded {...props} />
}

const ResourceFaq = (props) => {
  const Loaded = loadable(() => import("@fastly/consistently-icons/dist/icons/ResourceFaq"))

  return <Loaded {...props} />
}

const ResourceInfographic = (props) => {
  const Loaded = loadable(() => import("@fastly/consistently-icons/dist/icons/ResourceInfographic"))

  return <Loaded {...props} />
}

const ResourceSolutionsbrief = (props) => {
  const Loaded = loadable(() => import("@fastly/consistently-icons/dist/icons/ResourceSolutionsbrief"))

  return <Loaded {...props} />
}

const ResourceVideo = (props) => {
  const Loaded = loadable(() => import("@fastly/consistently-icons/dist/icons/ResourceVideo"))

  return <Loaded {...props} />
}

const ResourceWebpage = (props) => {
  const Loaded = loadable(() => import("@fastly/consistently-icons/dist/icons/ResourceWebpage"))

  return <Loaded {...props} />
}

const ResourceWhitePaper = (props) => {
  const Loaded = loadable(() => import("@fastly/consistently-icons/dist/icons/ResourceWhitePaper"))

  return <Loaded {...props} />
}

const Router = (props) => {
  const Loaded = loadable(() => import("@fastly/consistently-icons/dist/icons/Router"))

  return <Loaded {...props} />
}

const ScalableA = (props) => {
  const Loaded = loadable(() => import("@fastly/consistently-icons/dist/icons/ScalableA"))

  return <Loaded {...props} />
}

const ScalableB = (props) => {
  const Loaded = loadable(() => import("@fastly/consistently-icons/dist/icons/ScalableB"))

  return <Loaded {...props} />
}

const ScalableC = (props) => {
  const Loaded = loadable(() => import("@fastly/consistently-icons/dist/icons/ScalableC"))

  return <Loaded {...props} />
}

const ScalableD = (props) => {
  const Loaded = loadable(() => import("@fastly/consistently-icons/dist/icons/ScalableD"))

  return <Loaded {...props} />
}

const ScalableSecurityA = (props) => {
  const Loaded = loadable(() => import("@fastly/consistently-icons/dist/icons/ScalableSecurityA"))

  return <Loaded {...props} />
}

const ScalableSecurityB = (props) => {
  const Loaded = loadable(() => import("@fastly/consistently-icons/dist/icons/ScalableSecurityB"))

  return <Loaded {...props} />
}

const Searchability = (props) => {
  const Loaded = loadable(() => import("@fastly/consistently-icons/dist/icons/Searchability"))

  return <Loaded {...props} />
}

const SecurityPerformance = (props) => {
  const Loaded = loadable(() => import("@fastly/consistently-icons/dist/icons/SecurityPerformance"))

  return <Loaded {...props} />
}

const SelfHealing = (props) => {
  const Loaded = loadable(() => import("@fastly/consistently-icons/dist/icons/SelfHealing"))

  return <Loaded {...props} />
}

const Seo = (props) => {
  const Loaded = loadable(() => import("@fastly/consistently-icons/dist/icons/Seo"))

  return <Loaded {...props} />
}

const ServerA = (props) => {
  const Loaded = loadable(() => import("@fastly/consistently-icons/dist/icons/ServerA"))

  return <Loaded {...props} />
}

const ServerB = (props) => {
  const Loaded = loadable(() => import("@fastly/consistently-icons/dist/icons/ServerB"))

  return <Loaded {...props} />
}

const ServerlessCompute = (props) => {
  const Loaded = loadable(() => import("@fastly/consistently-icons/dist/icons/ServerlessCompute"))

  return <Loaded {...props} />
}

const ShieldA = (props) => {
  const Loaded = loadable(() => import("@fastly/consistently-icons/dist/icons/ShieldA"))

  return <Loaded {...props} />
}

const ShieldB = (props) => {
  const Loaded = loadable(() => import("@fastly/consistently-icons/dist/icons/ShieldB"))

  return <Loaded {...props} />
}

const SocialFacebook = (props) => {
  const Loaded = loadable(() => import("@fastly/consistently-icons/dist/icons/SocialFacebook"))

  return <Loaded {...props} />
}

const SocialInstagram = (props) => {
  const Loaded = loadable(() => import("@fastly/consistently-icons/dist/icons/SocialInstagram"))

  return <Loaded {...props} />
}

const SocialLinkedIn = (props) => {
  const Loaded = loadable(() => import("@fastly/consistently-icons/dist/icons/SocialLinkedIn"))

  return <Loaded {...props} />
}

const SocialTwitter = (props) => {
  const Loaded = loadable(() => import("@fastly/consistently-icons/dist/icons/SocialTwitter"))

  return <Loaded {...props} />
}

const SocialYoutube = (props) => {
  const Loaded = loadable(() => import("@fastly/consistently-icons/dist/icons/SocialYoutube"))

  return <Loaded {...props} />
}

const SpeedA = (props) => {
  const Loaded = loadable(() => import("@fastly/consistently-icons/dist/icons/SpeedA"))

  return <Loaded {...props} />
}

const SpeedB = (props) => {
  const Loaded = loadable(() => import("@fastly/consistently-icons/dist/icons/SpeedB"))

  return <Loaded {...props} />
}

const Stack = (props) => {
  const Loaded = loadable(() => import("@fastly/consistently-icons/dist/icons/Stack"))

  return <Loaded {...props} />
}

const Star = (props) => {
  const Loaded = loadable(() => import("@fastly/consistently-icons/dist/icons/Star"))

  return <Loaded {...props} />
}

const StreamlinedWorkflow = (props) => {
  const Loaded = loadable(() => import("@fastly/consistently-icons/dist/icons/StreamlinedWorkflow"))

  return <Loaded {...props} />
}

const SuccessA = (props) => {
  const Loaded = loadable(() => import("@fastly/consistently-icons/dist/icons/SuccessA"))

  return <Loaded {...props} />
}

const SuccessB = (props) => {
  const Loaded = loadable(() => import("@fastly/consistently-icons/dist/icons/SuccessB"))

  return <Loaded {...props} />
}

const Support = (props) => {
  const Loaded = loadable(() => import("@fastly/consistently-icons/dist/icons/Support"))

  return <Loaded {...props} />
}

const TlScertsA = (props) => {
  const Loaded = loadable(() => import("@fastly/consistently-icons/dist/icons/TlScertsA"))

  return <Loaded {...props} />
}

const TlScertsB = (props) => {
  const Loaded = loadable(() => import("@fastly/consistently-icons/dist/icons/TlScertsB"))

  return <Loaded {...props} />
}

const Tls = (props) => {
  const Loaded = loadable(() => import("@fastly/consistently-icons/dist/icons/Tls"))

  return <Loaded {...props} />
}

const TransparentInvironment = (props) => {
  const Loaded = loadable(() => import("@fastly/consistently-icons/dist/icons/TransparentInvironment"))

  return <Loaded {...props} />
}

const Trash = (props) => {
  const Loaded = loadable(() => import("@fastly/consistently-icons/dist/icons/Trash"))

  return <Loaded {...props} />
}

const UserA = (props) => {
  const Loaded = loadable(() => import("@fastly/consistently-icons/dist/icons/UserA"))

  return <Loaded {...props} />
}

const UserB = (props) => {
  const Loaded = loadable(() => import("@fastly/consistently-icons/dist/icons/UserB"))

  return <Loaded {...props} />
}

const UserC = (props) => {
  const Loaded = loadable(() => import("@fastly/consistently-icons/dist/icons/UserC"))

  return <Loaded {...props} />
}

const UserD = (props) => {
  const Loaded = loadable(() => import("@fastly/consistently-icons/dist/icons/UserD"))

  return <Loaded {...props} />
}

const UserE = (props) => {
  const Loaded = loadable(() => import("@fastly/consistently-icons/dist/icons/UserE"))

  return <Loaded {...props} />
}

const UserF = (props) => {
  const Loaded = loadable(() => import("@fastly/consistently-icons/dist/icons/UserF"))

  return <Loaded {...props} />
}

const VisibilityA = (props) => {
  const Loaded = loadable(() => import("@fastly/consistently-icons/dist/icons/VisibilityA"))

  return <Loaded {...props} />
}

const VisibilityB = (props) => {
  const Loaded = loadable(() => import("@fastly/consistently-icons/dist/icons/VisibilityB"))

  return <Loaded {...props} />
}

const VisibilityHide = (props) => {
  const Loaded = loadable(() => import("@fastly/consistently-icons/dist/icons/VisibilityHide"))

  return <Loaded {...props} />
}

const VisibilityShow = (props) => {
  const Loaded = loadable(() => import("@fastly/consistently-icons/dist/icons/VisibilityShow"))

  return <Loaded {...props} />
}

const Waf = (props) => {
  const Loaded = loadable(() => import("@fastly/consistently-icons/dist/icons/Waf"))

  return <Loaded {...props} />
}

const Warning = (props) => {
  const Loaded = loadable(() => import("@fastly/consistently-icons/dist/icons/Warning"))

  return <Loaded {...props} />
}

const ZeroRating = (props) => {
  const Loaded = loadable(() => import("@fastly/consistently-icons/dist/icons/ZeroRating"))

  return <Loaded {...props} />
}

export {
  Arrow,
  BotA,
  BotB,
  Browser,
  Cancel,
  Check,
  CheckboxCheck,
  CheckboxDash,
  Chevron,
  Clear,
  CloudMigration,
  ControlA,
  ControlB,
  Cycle,
  DDoSA,
  DDoSB,
  DevOps,
  Devices,
  Docs,
  Dollar,
  Download,
  Error,
  Failover,
  FastlyBuilt,
  FastlyLogo,
  GatedAccess,
  GlobeA,
  GlobeB,
  GlobeC,
  IPblocking,
  ImageOptimization,
  Info,
  LanguageJavascript,
  LanguageRust,
  LanguageWebAssembly,
  Like,
  Link,
  ListA,
  ListB,
  ListC,
  Location,
  LockA,
  LockB,
  Login,
  ModernWeb,
  NoEdit,
  NonSni,
  PartnerBuilt,
  Partners,
  Plus,
  RateLimiting,
  ResourceAnalystReport,
  ResourceCaseStudy,
  ResourceDataSheet,
  ResourceEbook,
  ResourceFaq,
  ResourceInfographic,
  ResourceSolutionsbrief,
  ResourceVideo,
  ResourceWebpage,
  ResourceWhitePaper,
  Router,
  ScalableA,
  ScalableB,
  ScalableC,
  ScalableD,
  ScalableSecurityA,
  ScalableSecurityB,
  Searchability,
  SecurityPerformance,
  SelfHealing,
  Seo,
  ServerA,
  ServerB,
  ServerlessCompute,
  ShieldA,
  ShieldB,
  SocialFacebook,
  SocialInstagram,
  SocialLinkedIn,
  SocialTwitter,
  SocialYoutube,
  SpeedA,
  SpeedB,
  Stack,
  Star,
  StreamlinedWorkflow,
  SuccessA,
  SuccessB,
  Support,
  TlScertsA,
  TlScertsB,
  Tls,
  TransparentInvironment,
  Trash,
  UserA,
  UserB,
  UserC,
  UserD,
  UserE,
  UserF,
  VisibilityA,
  VisibilityB,
  VisibilityHide,
  VisibilityShow,
  Waf,
  Warning,
  ZeroRating,
}
