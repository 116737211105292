import { useEffect, useState } from "react"

const useMedia = (query, defaultState = false) => {
  const [state, setState] = useState(
    typeof window !== "undefined" ? () => window.matchMedia(query).matches : defaultState
  )

  useEffect(() => {
    let mounted = true
    const mql = window.matchMedia(query)
    const onChange = () => {
      if (!mounted) {
        return
      }
      setState(!!mql.matches)
    }

    mql.addListener(onChange)
    setState(mql.matches)

    return () => {
      mounted = false
      mql.removeListener(onChange)
    }
  }, [query])

  return state
}

export default useMedia

export const mdMediaQuery = "(min-width: 768px)"
export const lgMediaQuery = "(min-width: 992px)"
export const xlMediaQuery = "(min-width: 1200px)"
