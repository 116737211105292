import { useState } from "react"
import styled from "styled-components"
import PlusCircle from "../../images/icons/icon-plus-circle.inline.svg"

const ShowMoreButton = styled.button`
  font-family: var(--font);
  font-weight: 700;
  border: 0;
  background: transparent;
  display: flex;
  align-items: center;
  gap: var(--size-space-sm);
  margin: 1.5em auto;
  border-radius: var(--size-radius-xs);
  transition: color 0.25s ease-in-out, fill 0.25s ease-in-out;

  &:hover,
  &:focus-visible {
    transition: color 0.25s ease-in-out;
    color: var(--color-v3-blue-70);
    cursor: pointer;

    svg path {
      transition: fill 0.25s ease-in-out;
      fill: var(--color-v3-blue-70);
    }
  }

  &:focus-visible {
    outline-offset: var(--size-space-xs);
  }
`

const CollapsedList = ({ max = 6, buttonCopyGroup, children, StyledList, onClickOverride }) => {
  const [collapsed, setCollapsed] = useState(true)

  const onClickHandler = () => {
    if (onClickOverride) {
      onClickOverride()
    }

    // still set collapse
    setCollapsed(false)
  }

  if (!Array.isArray(children) || !buttonCopyGroup) return null

  const listItems = collapsed && children.length > max ? children.slice(0, max) : children
  const hiddenItemCount = children.length - max

  const singularText = buttonCopyGroup.items.find((item) => item.title?.match("singular"))
  const pluralText = buttonCopyGroup.items.find((item) => item.title?.match("plural"))
  if (!singularText?.text || !pluralText?.text) return null

  let buttonCopy = hiddenItemCount === 1 ? singularText.text : pluralText.text
  buttonCopy = buttonCopy.replace("{count}", hiddenItemCount)

  return (
    <div>
      {StyledList ? <StyledList>{listItems}</StyledList> : <ul>{listItems}</ul>}

      {collapsed ? (
        <ShowMoreButton onClick={() => onClickHandler()}>
          {buttonCopy}
          <PlusCircle />
        </ShowMoreButton>
      ) : null}
    </div>
  )
}

export default CollapsedList
