import { useState } from "react"
import PropTypes from "prop-types"
import Modal from "react-modal"
import { modal, modalPlay, modalBody, overlay, close } from "./Modal.module.css"
import { Button as ConsistentlyButton } from "@fastly/consistently"
import { MikanSpan } from "../Mikan"

const ModalComponent = ({ modalId, buttonData, children }) => {
  const [isOpen, setOpen] = useState(false)

  const handleCloseModal = () => setOpen(false)

  // TODO: implement custom attributes
  // @TODO: translate close modal

  return (
    <div className={`${modal} ${buttonData.type === "play" ? modalPlay : ""}`}>
      <Modal
        id={modalId}
        className={modalBody}
        isOpen={isOpen}
        onRequestClose={handleCloseModal}
        overlayClassName={overlay}
      >
        {children}
        <button className={close} onClick={handleCloseModal} aria-label="Close"></button>
      </Modal>

      {buttonData.type === "play" ? (
        <button
          aria-label="play"
          className={`button-new button-new--${buttonData.type}`}
          onClick={() => setOpen(true)}
        />
      ) : (
        <ConsistentlyButton variant="link" icon="arrow" textColor={["blue-light"]} onClick={() => setOpen(true)}>
          <MikanSpan text={buttonData.buttonText} />
        </ConsistentlyButton>
      )}
    </div>
  )
}

export default ModalComponent

ModalComponent.propTypes = {
  buttonData: PropTypes.object,
}

ModalComponent.defaultProps = {
  buttonData: {
    customAttributes: null,
    buttonText: "",
    type: "main",
  },
}
