import { memo } from "react"
import styled, { css } from "styled-components"
import { useTextParser } from "@hooks/use-text-parser"
import { JAPANESE_CHARACTERS_REGEX } from "@utils/text-parser/mikan-plugin"
import { BodyCopy } from "@fastly/consistently"

const LocalizedP = styled.p`
  display: block;

  ${({ locale }) =>
    locale === "ja" &&
    css`
      text-align: justify;
    `}
`

export const LocalizedParagraph = ({ children, as }) => (
  <LocalizedP as={as} locale={process.env.GATSBY_QUERY_LOCALE}>
    {children}
  </LocalizedP>
)

const RTBodyCopy = styled(BodyCopy).attrs({ as: "p" })`
  ${({ locale }) =>
    locale === "ja" &&
    css`
      text-align: justify;
    `}
`

export const LocalizedRTBodyCopy = ({ children }) => (
  <RTBodyCopy locale={process.env.GATSBY_QUERY_LOCALE}>{children}</RTBodyCopy>
)

/**
 * Component that for the JP locale render text with proper linebreaks for this component
 * For languages other than Japanese returns not modified 'text' input param
 *
 *
 * @param {string} text - deprecated - plain text or html text
 * @param {string} children - plain text or html text
 * @param {boolean} justify - force text justify (if text has JP characters)
 * @param {boolean} disableBreaks - turn off new line to br parser
 * @returns React.Element Processed string
 */
export const MikanSpan = memo(
  ({
    children,
    /** @deprecated since new parser */
    text,
    justify,
    disableBreaks,
  }) => {
    const toParse = children || text

    const textParser = useTextParser()

    const processed = textParser.process(toParse, {
      disableMikan: justify,
      disableBreaks,
    })

    // If text does not contain JP chars, do not justify, even if forced
    const disableJustify = !JAPANESE_CHARACTERS_REGEX.test(toParse)

    if (justify && !disableJustify) {
      // We are using it as span to force justify without breaking html
      return <LocalizedParagraph as="span">{processed}</LocalizedParagraph>
    }

    return processed
  }
)

MikanSpan.displayName = "MikanSpan"
