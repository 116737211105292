import { useLocation } from "@reach/router"
import { graphql, navigate, useStaticQuery } from "gatsby"

import {
  LANGUAGES,
  LanguageSelector as LanguageSelectorRoot,
  LocaleOption,
  LocalePrefixes,
  Locales,
} from "@fastly/consistently-vanilla"

type LanguageSelectorProps = {
  id?: string
}

const isSupportedLanguage = (prefix: unknown): prefix is Locales => {
  return Object.keys(LANGUAGES).some((key) => key === prefix)
}

const currentLocalePrefix: LocalePrefixes =
  LANGUAGES[isSupportedLanguage(process.env.GATSBY_QUERY_LOCALE) ? process.env.GATSBY_QUERY_LOCALE : "en-US"]

export const setLangCookie = () => {
  document.cookie = `lang=true; domain=fastly.com; path=/;`

  // remove previously set cookie on www.fastly.com - this should be set on fastly.com so it works across subdomains such as developer.fastly.com
  document.cookie = "lang=; expires=Thu, 01 Jan 1970 00:00:00 GMT; domain=.www.fastly.com; path=/;"
}

export const LanguageSelector = ({ id = "language-selector" }: LanguageSelectorProps) => {
  const location = useLocation()

  const { localesData } = useStaticQuery<Queries.LocalesDataQuery>(graphql`
    query LocalesData {
      localesData: contentfulLink(contentful_id: { eq: "2A6WWx980JqazqtG501UzB" }) {
        locales: links {
          name
          url
          id
        }
      }
    }
  `)

  if (!localesData || !localesData.locales) return null

  const locales: LocaleOption[] = []
  localesData.locales.forEach((l) => {
    if (l !== null && l.name && l.id && l.url) {
      locales.push({
        name: l.name,
        id: l.id,
        languageCode: l.url,
      })
    }
  })

  const handleSelect = (selectedLocale: LocaleOption) => {
    const locationPath = (location.pathname + location.search).replace(/^\/(es\/|de\/|fr\/|jp\/)/, "/")

    // languageCode is actually the link, so "" is english
    const redirectLocation =
      selectedLocale.languageCode === "/"
        ? location.origin + locationPath
        : location.origin + selectedLocale.languageCode + locationPath

    setLangCookie()

    navigate(redirectLocation)
  }

  return (
    <LanguageSelectorRoot defaultSelected={currentLocalePrefix} id={id} locales={locales} onSelect={handleSelect} />
  )
}
