export default function useDataLayer() {
  const sendDataLayer = (dataLayer) => {
    window.dataLayer.push({ event: `${dataLayer}` })

    return true
  }

  return {
    sendDataLayer,
  }
}
