import mikan from "mikanjs"

// Regex for Japanese characters
// Taken from: https://stackoverflow.com/questions/15033196/using-javascript-to-check-whether-a-string-contains-japanese-characters-includi
export const JAPANESE_CHARACTERS_REGEX =
  /[\u3000-\u303f\u3040-\u309f\u30a0-\u30ff\uff00-\uff9f\u4e00-\u9faf\u3400-\u4dbf]/

function rehypeMikan() {
  // Array of elements which should not be parsed
  const OMIT_ELEMENTS = ["pre"]
  // Array of elements which should be justified
  const JUSTIFY_ELEMENTS = ["p", "blockquote"]

  /**
   * Options object
   * locale: string
   * forceTextJustify: boolean
   * forceMikan: boolean
   * disableMikan: boolean
   * displayInline: boolean
   */
  const defaultOptions = {
    locale: undefined,
    forceTextJustify: false,
    forceMikan: false,
    disableMikan: false,
    displayInline: false,
  }
  const options = this.data().mikan ?? defaultOptions

  // Force mikan has higher priority than disable mikan
  if (options.forceMikan) {
    options.disableMikan = false
  }

  // If locale is not JP, we don't need this transformer
  if (options.locale !== "ja" || options.disableMikan) {
    return
  }

  const constructMikanSpanWrap = (text) => {
    // In case of sole space as part of mikan split
    // we need to change display to show space character
    const isPunctuationMark = text.includes(" ")
    const styles = [
      "word-break: keep-all",
      isPunctuationMark || options.displayInline ? "display:inline" : "display:inline-block",
    ]

    return {
      type: "element",
      tagName: "span",
      properties: {
        style: styles.join(";"),
        class: "Mikan",
        role: "presentation",
      },
      children: [
        {
          type: "text",
          value: text,
        },
      ],
    }
  }

  const constructJustifySpanWrap = (text) => ({
    type: "element",
    tagName: "span",
    properties: {
      style: "text-align:justify",
    },
    children: [
      {
        type: "text",
        value: text,
      },
    ],
  })

  const hasJPChars = (node) => JAPANESE_CHARACTERS_REGEX.test(node.value)

  const elementHasJPChars = (node) => {
    return node.children.every((child) => {
      if (child.type === "text") {
        return hasJPChars(child)
      }

      return elementHasJPChars(child)
    })
  }

  const transformer = (tree) => {
    // We need to add first item to queue to start traversing
    // Tree is root node here
    const queue = [tree]

    // BFS tree traversal
    while (queue.length > 0) {
      // Take first item from queue
      const node = queue.shift()
      const toReplace = []

      // We are making traversal to parent level
      // Eg. The text nodes (without children) will never be added to queue
      // Without parent reference, we could not replace nodes
      if (node.children) {
        node.children.forEach((child, index) => {
          if (child.type === "element" && OMIT_ELEMENTS.includes(child.tagName)) {
            return
          }
          // For some selected element or if we set it explicitly
          // we apply justification instead of splitting content with mikan
          if (!options.forceMikan && child.type === "element" && JUSTIFY_ELEMENTS.includes(child.tagName)) {
            // Don't justify if text does not contain jp chars
            if (elementHasJPChars(child)) {
              child.properties = {
                ...child.properties,
                style: `text-align:justify;${child.properties.style || ""}`,
              }
            }
            return
          }

          // If child type is not text, we can assume that it contains more children
          if (child.type !== "text") {
            queue.push(child)
            return
          }

          // If text does not contain japanese characters, we can omit splitting
          if (!hasJPChars(child)) {
            return
          }

          // If we want to force text justify, transformer will wrap text in span
          // Its main usage is for texts without html markup
          if (options.forceTextJustify) {
            if (child.type === "text") {
              toReplace.push({
                index,
                newChildren: [constructJustifySpanWrap(child.value)],
              })
              return
            }
          }

          // For text nodes we split text value into groups
          // According to mikan rules
          const val = mikan.split(child.value)

          // After split, we construct new children as AST items
          toReplace.push({
            index,
            newChildren: val.map(constructMikanSpanWrap),
          })
        })

        // Offset needed when adding more children than originaly
        let offset = 0
        toReplace.forEach(({ index, newChildren }) => {
          // We want to remove text node and replace it with spans
          node.children.splice(index + offset, 1, ...newChildren)
          // We need to change offest depending on newChildren added
          // If we add only one children, original array is not extended
          // Thus we need to subtract 1
          offset = offset + newChildren.length - 1
        })
      }
    }
  }

  return transformer
}

export default rehypeMikan
