import { Box, Stack, Text } from "@fastly/consistently"
import styled, { css } from "styled-components"
import { MikanSpan } from "@components/Mikan"
import RichText from "@components/rich-text/renderer"

const KeyStatWrapper = styled((props) => <Box {...props} />)`
  ${({ theme }) => css`
    padding: ${theme.space.sm} ${theme.space.lg};
    margin: ${theme.space.xl} 0;
    position: relative;
    background-color: var(--white);
    border-radius: ${({ theme }) => theme.radii.md};
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12); // none of shadow tokens align with design requirements

    &::after {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      left: -3.5px;
      top: 0;
      background-image: linear-gradient(var(--blue), var(--green-light));
      border-radius: ${({ theme }) => theme.radii.md};
      z-index: -1;
    }

    h4.title {
      margin-bottom: ${theme.space.md};
    }

    p.statDescription {
      margin-bottom: 0;
    }

    p.stat {
      font-size: ${theme.fontSizes["5xl"]};
      color: var(--green-darkest);
    }
  `}
`

const Source = styled((props) => <Stack {...props} />)`
  margin-top: ${({ theme }) => theme.space.sm};
  color: var(--grey-60);

  p,
  & {
    font: var(--text-sm);
    margin-bottom: 0;
  }
`

export const KeyStat = ({ data }) => {
  const { statTitle, stat, statDescription } = data

  return (
    <KeyStatWrapper>
      {statTitle ? (
        <Text as="h4" variant="xl" alignText="left" className="title">
          <MikanSpan text={statTitle} />
        </Text>
      ) : null}
      <Text as="p" variant="displaymd" alignText="left" className="stat">
        <MikanSpan text={stat} />
      </Text>{" "}
      <Text as="p" variant="xl" alignText="left" className="statDescription">
        <MikanSpan text={statDescription.internal.content} />
      </Text>
      {data.source ? (
        <Source direction="horizontal" gap="2xs">
          <span>Source:</span>
          <RichText body={data.source} />
        </Source>
      ) : null}
    </KeyStatWrapper>
  )
}
