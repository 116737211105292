import { Box } from "@fastly/consistently"
import RichText from "@components/rich-text/renderer"
import styled from "styled-components"

const SpotlightWrapper = styled((props) => <Box {...props} />)`
  background-color: var(--blue-lighter);
  padding: ${({ theme }) => theme.space.lg};
  border-radius: ${({ theme }) => theme.radii.md};
  margin: ${({ theme }) => theme.space["2xl"]} 0;

  h3:first-child,
  h4:first-child {
    margin-top: unset;
  }

  ul li {
    list-style: none;
    color: var(--blue-darkest);
    position: relative;

    &::before {
      content: "";
      mask: url("/g-static/icons/icon-check.svg");
      mask-size: cover;
      display: inline-block;
      height: 1.25rem;
      width: 1.25rem;
      margin-left: -2rem;
      background-color: var(--blue-dark);
      position: absolute;
      top: -1px;
      left: 0;
    }
  }

  p:last-of-type {
    margin-bottom: 0;
  }
`

export const Spotlight = ({ data }) => {
  return (
    <>
      <SpotlightWrapper>
        <RichText body={data.spotlight} />
      </SpotlightWrapper>
    </>
  )
}
