import { graphql, useStaticQuery, withPrefix } from "gatsby"
import { useState } from "react"

import {
  Button,
  NavigationMenu,
  NavigationMenuActions,
  NavigationMenuContentItem,
  NavigationMenuContentList,
  NavigationMenuFeaturedItem,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
  NavigationMenuLogo,
  NavigationMenuMain,
  NavigationMenuPopoverContent,
  NavigationMenuTabList,
  NavigationMenuTabPanel,
  NavigationMenuTabRoot,
  NavigationMenuTabTrigger,
  NavigationMenuTrigger,
  SearchProvider,
  SecondaryNavigation,
  sprinkles,
  useStickyNavigation,
} from "@fastly/consistently-vanilla"

import { FastlyLogo } from "@fastly/consistently-icons"

import { BrandRefreshProvider } from "@components/BrandRefreshProvider"
import { LanguageSelector } from "@components/LanguageSelector"

import MobileMenuWrapper from "../MobileMenu/MobileMenu"

import { Search } from "../Search"

import * as styles from "./Header.css"

const Header = () => {
  const { nav, actions, topNav } = useStaticQuery(graphql`
    query {
      nav: contentfulNavigationMenu(contentful_id: { eq: "2XpI4z8NChYICcrKiQNCy1" }) {
        navigationItems {
          name
          description
          url
          type
          navigationItems {
            name
            description
            eyebrow
            url
            type
            image {
              ... on ContentfulProductLineIcon {
                iconLarge {
                  svg {
                    content
                  }
                }
                monochromeIconSmall {
                  svg {
                    content
                  }
                }
              }
            }
            featuredCta {
              text
            }
            navigationItems {
              name
              description
              url
              type
            }
          }
        }
      }
      actions: contentfulNavigationMenu(contentful_id: { eq: "2HMRKToD79wlmR3mlGbk3R" }) {
        navigationItems {
          name
          description
          url
        }
      }
      topNav: contentfulNavigationMenu(contentful_id: { eq: "5lQg7jTGIMt1WQyKYJEhHh" }) {
        navigationItems {
          name
          description
          url
          contentful_id
        }
      }
    }
  `)

  const { isSticky, navigationRef } = useStickyNavigation(0)

  const [isSearchFieldOpen, setIsSearchFieldOpen] = useState(false)

  const handleSearchOpen = () => {
    setIsSearchFieldOpen(true)
  }

  const handleSearchBlur = () => {
    setIsSearchFieldOpen(false)
  }

  return (
    <div className={["reset-override", styles.stickyHeader].join(" ")}>
      <BrandRefreshProvider>
        <div className={sprinkles({ display: { _: "none", lg: "block" } })} data-swiftype-index="false">
          <SecondaryNavigation>
            <div data-nav-fastly-logo>
              <a href={withPrefix("/")} title="Home">
                <FastlyLogo color="white" role="img" title="Fastly" width="100" />
              </a>
            </div>
            <div className={isSearchFieldOpen ? styles.searchOverlay.secondary : styles.secondary}>
              <LanguageSelector />
              {topNav.navigationItems.map((item: any) => {
                if (item.contentful_id === "51K68wPjyWfpk4QS3q8WZM") {
                  return (
                    <a key={item.contentful_id} href={item.url} id="under-attack-button">
                      {item.name}
                    </a>
                  )
                }

                return (
                  <Button key={item.contentful_id} colorModes="dark" size="compact" url={item.url} variant="text">
                    {item.description}
                  </Button>
                )
              })}
            </div>
          </SecondaryNavigation>
          <NavigationMenu ref={navigationRef} isSticky={isSticky}>
            <NavigationMenuMain>
              <div className={isSearchFieldOpen ? styles.searchOverlay.primary : ""}>
                <NavigationMenuList>
                  {nav.navigationItems.map((item: any) => {
                    const featured = item?.navigationItems?.filter((item: any) => item.type === "featured")[0]
                    const categories = item?.navigationItems?.filter((item: any) => item.type === "category")
                    const restItems = item?.navigationItems?.filter(
                      (item: any) => item.type !== "category" && item.type !== "featured"
                    )

                    return (
                      <NavigationMenuItem key={item.name}>
                        {item.url ? (
                          <NavigationMenuLink href={withPrefix(item.url)}>{item.name}</NavigationMenuLink>
                        ) : (
                          <NavigationMenuTrigger>{item.name}</NavigationMenuTrigger>
                        )}
                        {item?.navigationItems?.length > 0 ? (
                          <NavigationMenuPopoverContent tabIndex={-1}>
                            {featured ? (
                              <NavigationMenuFeaturedItem
                                ctaText={featured.featuredCta?.text}
                                eyebrow={featured.eyebrow}
                                icon={
                                  featured.image?.iconLarge?.svg?.content ? (
                                    <div
                                      dangerouslySetInnerHTML={{ __html: featured.image.iconLarge.svg.content }}
                                      style={{ width: "96px" }}
                                    />
                                  ) : null
                                }
                                title={featured.name}
                                url={featured.url}
                              >
                                {featured.description}
                              </NavigationMenuFeaturedItem>
                            ) : null}
                            {categories.length > 0 ? (
                              <NavigationMenuTabRoot defaultValue="0" orientation="vertical">
                                <NavigationMenuTabList>
                                  {categories.map((l2: any, i: number) => (
                                    <NavigationMenuTabTrigger
                                      key={l2.name}
                                      icon={
                                        l2.image?.iconLarge?.svg?.content ? (
                                          <span
                                            dangerouslySetInnerHTML={{ __html: l2.image.iconLarge.svg.content }}
                                            style={{ width: "40px" }}
                                          />
                                        ) : null
                                      }
                                      title={l2.name}
                                      value={i.toString()}
                                    >
                                      {l2.description ? l2.description : null}
                                    </NavigationMenuTabTrigger>
                                  ))}
                                </NavigationMenuTabList>
                                {categories.map((l2: any, i: number) => (
                                  <NavigationMenuTabPanel key={l2.name} value={i.toString()}>
                                    <NavigationMenuContentList>
                                      {l2.navigationItems?.map((l3: any) => (
                                        <NavigationMenuContentItem
                                          key={l3.name}
                                          href={withPrefix(l3.url)}
                                          title={l3.name}
                                        >
                                          {l3.description}
                                        </NavigationMenuContentItem>
                                      ))}
                                    </NavigationMenuContentList>
                                  </NavigationMenuTabPanel>
                                ))}
                              </NavigationMenuTabRoot>
                            ) : null}
                            <NavigationMenuContentList>
                              {restItems.map((l2: any) => (
                                <NavigationMenuContentItem key={l2.name} href={withPrefix(l2.url)} title={l2.name}>
                                  {l2.description}
                                </NavigationMenuContentItem>
                              ))}
                            </NavigationMenuContentList>
                          </NavigationMenuPopoverContent>
                        ) : null}
                      </NavigationMenuItem>
                    )
                  })}
                </NavigationMenuList>
              </div>
            </NavigationMenuMain>

            <NavigationMenuActions>
              <SearchProvider minCharacters={3} groupResults={false}>
                <Search handleSearchBlur={handleSearchBlur} handleSearchOpen={handleSearchOpen} />
              </SearchProvider>
              {actions?.navigationItems?.map((a, i) => (
                <>
                  {i === 0 ? (
                    <Button key={a.name} url={withPrefix(a.url)} colorModes="dark" variant="outline" size="compact">
                      {a.name}
                    </Button>
                  ) : (
                    <Button key={a.name} url={withPrefix(a.url)} colorModes="dark" size="compact">
                      {a.name}
                    </Button>
                  )}
                </>
              ))}
            </NavigationMenuActions>
          </NavigationMenu>
        </div>
        <div className={sprinkles({ display: { _: "block", lg: "none" } })} data-swiftype-index="false">
          <MobileMenuWrapper navData={nav} actions={actions} topNav={topNav} />
        </div>
      </BrandRefreshProvider>
    </div>
  )
}
export default Header
