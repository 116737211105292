import { useCallback, useEffect, useState } from "react"
import debounce from "lodash.debounce"

export enum FetchStatus {
  Idle,
  Pending,
  Fulfilled,
}

const url = "https://search-api.swiftype.com/api/v1/public/installs/x19oDzidpFguHpeE5zrw/search.json"

type PageRecord = {
  title: string
  id: string
  body: string
  url: string
}

const DEBOUNCE_INTERVAL = 300

export function useSwiftype(searchQuery = "") {
  const [results, setResults] = useState<PageRecord[] | null>(null)
  const [status, setStatus] = useState(FetchStatus.Idle)

  const fetchResults = useCallback(
    debounce(() => {
      fetch(url, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          engine_key: process.env.GATSBY_SWIFTYPE_PUBLIC_ENGINE_KEY,
          q: searchQuery,
        }),
      })
        .then((res) => res.json())
        .then((json) => {
          if (json.records) {
            setStatus(FetchStatus.Fulfilled)
            setResults(json.records.page as PageRecord[])
          }
        })
    }, DEBOUNCE_INTERVAL),
    [searchQuery, setStatus, setResults]
  )

  useEffect(() => {
    if (searchQuery?.length >= 3) {
      setStatus(FetchStatus.Pending)

      fetchResults()
    } else {
      setStatus(FetchStatus.Idle)
      setResults(null)
    }

    return () => {
      fetchResults.cancel()
    }
  }, [searchQuery, fetchResults])

  return { results, status }
}
