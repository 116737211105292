import { createContext, useContext } from "react"

export const FeatureFlagContext = createContext({})
export const FeatureFlagProvider = FeatureFlagContext.Provider

/**
 * The useFeatureFlags hook provides the feature flag context value to components.
 *
 * @example
 * ```jsx
 * import React from 'react';
 * import { useFeatureFlags } from '@/hooks/use-feature-flag';
 *
 * const Component = () => {
 *   const featureFlags = useFeatureFlags()
 *   return (
 *     <div>
 *       {featureFlags["my-feature"]?.isEnabled ? "my feature is on" : "my feature is off"}
 *     </div>
 *   )
 * };
 * ```
 */
export const useFeatureFlags = () => useContext(FeatureFlagContext)

/**
 * The useFeatureFlag hook makes it easy to conditionally render a component based on the value of a feature flag.
 * The value of the feature flag can be either destructured as an array `[bool]` or a named `{ isEnabled }` key
 *
 * @example
 * ```jsx
 * import React from 'react';
 * import { useFeatureFlag } from '@/hooks/use-feature-flag';
 *
 * const Component = () => {
 *   const [isMyFeatureOn] = useFeatureFlag("my-feature")
 *   return (
 *     <div>
 *       {isMyFeautureOn ? "my feature is on" : "my feature is off"}
 *     </div>
 *   )
 * };
 * ```
 */
export const useFeatureFlag = (name) => {
  const featureFlags = useFeatureFlags()
  const isEnabled = featureFlags[name]?.isEnabled || false
  const value = [isEnabled]
  value.isEnabled = isEnabled
  return value
}
