import textParser from "@utils/text-parser"

const defaultParams = {
  forceTextJustify: false,
  forceMikan: false,
  disableMikan: false,
  displayInline: false,
  disableBreaks: false,
}

export function useTextParser(passedParams) {
  const params = Object.assign({}, defaultParams, passedParams)

  /**
   * @param {string} value
   * @param {boolean} forceTextJustify
   * @param {boolean} forceMikan
   * @param {boolean} disableMikan
   * @param {boolean} displayInline
   * @returns React.Children
   */
  const processText = (
    value,
    {
      forceTextJustify = params.forceTextJustify,
      forceMikan = params.forceMikan,
      disableMikan = params.disableMikan,
      displayInline = params.displayInline,
      disableBreaks = params.disableBreaks,
    } = {}
  ) => {
    return textParser()
      .data({
        mikan: {
          locale: process.env.GATSBY_QUERY_LOCALE,
          forceTextJustify,
          forceMikan,
          disableMikan,
          displayInline,
        },
        disableBreaks,
      })
      .processSync(value).result
  }

  return { process: processText }
}
