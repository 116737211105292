import styled from "styled-components"
import slugify from "slugify"

/* TODO:
this content model is only used a handful of times,
1. handle bg video in other video types to remove content model
(see ticket )
2. migrate sass styling to styled components
*/

const StyledVideo = styled.div`
  // bg-video styling in industry.scss specific to industry pages
  video {
    width: 100%;
  }
`

const BackgroundVideo = ({ target }) => {
  let video_id = `bg-video--${slugify(target.title)}`
  return (
    <StyledVideo id={video_id} className="bg-video">
      <video playsInline autoPlay loop muted>
        <source src={target.videoFile?.file.url} type="video/mp4" />
        {target.fallbackImage && <img src={target.fallbackImage?.file.url} alt={target.fallbackImage.title} />}
      </video>
    </StyledVideo>
  )
}

export default BackgroundVideo
