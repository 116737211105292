import { graphql, useStaticQuery, withPrefix } from "gatsby"

import partition from "lodash.partition"

import {
  FooterBottomColumn,
  FooterBottomContainer,
  FooterLink,
  FooterLinksGroup,
  FooterLinksGroupHeading,
  FooterLinksGroupsContainer,
  FooterLinksList,
  FooterLogoWrapper,
  Footer as FooterRoot,
  FooterText,
} from "@fastly/consistently-vanilla"

import { MdArrowOutward } from "react-icons/md"

import { BrandRefreshProvider } from "./BrandRefreshProvider"

import logo from "../images/logo.svg"

const currentYear = new Date().getFullYear()

const Footer = () => {
  const { footerData, social } = useStaticQuery(graphql`
    query {
      footerData: contentfulNavigationMenu(contentful_id: { eq: "6qxj7UYEirLOutzGoQ9HXy" }) {
        navigationItems {
          name
          url
          contentful_id
          navigationItems {
            name
            url
            contentful_id
          }
        }
      }
      social: contentfulNavigationMenu(contentful_id: { eq: "5eUJWCjv6ZOSG6UhN7lrwH" }) {
        links: navigationItems {
          name
          url
          contentful_id
        }
      }
    }
  `)

  const [groups, subFooter] = partition(footerData.navigationItems, ({ navigationItems }) => navigationItems !== null)

  return (
    <BrandRefreshProvider>
      <FooterRoot data-swiftype-index="false">
        <FooterLogoWrapper>
          <img alt="Fastly" src={logo} />
        </FooterLogoWrapper>
        <FooterLinksGroupsContainer>
          {groups.map((g) => {
            return (
              <FooterLinksGroup key={g.contentful_id}>
                <FooterLinksGroupHeading>{g.name}</FooterLinksGroupHeading>
                <FooterLinksList>
                  {g.navigationItems.map((i) => {
                    return (
                      <FooterLink href={withPrefix(i.url)} key={i.contentful_id}>
                        {i.name}
                      </FooterLink>
                    )
                  })}
                </FooterLinksList>
              </FooterLinksGroup>
            )
          })}
        </FooterLinksGroupsContainer>
        <FooterBottomContainer>
          <FooterBottomColumn>
            <FooterText>©&nbsp;Fastly&nbsp;{currentYear}</FooterText>
            <FooterLinksList flexDirection={{ _: "column", md: "row" }} gap="xl">
              {subFooter.map((i) => {
                return (
                  <FooterLink href={withPrefix(i.url)} underlined key={i.contentful_id}>
                    {i.name}
                  </FooterLink>
                )
              })}
            </FooterLinksList>
          </FooterBottomColumn>
          <FooterBottomColumn>
            <FooterLinksList flexDirection={{ _: "column", lg: "row" }} gap="xl">
              {social.links.map((link) => {
                return (
                  <FooterLink href={link.url} key={link.contentful_id}>
                    {link.name}
                    <MdArrowOutward />
                  </FooterLink>
                )
              })}
            </FooterLinksList>
          </FooterBottomColumn>
        </FooterBottomContainer>
      </FooterRoot>
    </BrandRefreshProvider>
  )
}

export default Footer
