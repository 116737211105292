import idFromNode from "./idFromNode"
import { Fragment } from "react"

const renderTextNode = (node, children, Component, textParser, mikan = false) => {
  if (
    node.content.length === 1 &&
    node.content[0].marks &&
    node.content[0].marks.length === 1 &&
    node.content[0].marks[0]["type"] === "code"
  ) {
    // This is a code block
    return <pre>{children}</pre>
  }

  const renderText = (text) => {
    if (typeof text !== "string") {
      return text
    }

    // If mikan option is selected, always use mikan
    // Otherwise, check if node is paragraph and omit mikan formatting
    const disableMikan = mikan ? false : node.nodeType === "paragraph"

    return textParser.process(text, {
      // For paragraphs, we are using justification not mikan splitting
      disableMikan,
      displayInline: node.nodeType === "hyperlink",
    })
  }

  let mappedChildren = children
    .map((child) => {
      if (Array.isArray(child)) {
        return child.map((text) => renderText(text))
      }
      return renderText(child)
    })
    .flat()

  const headingId = node.nodeType.includes("heading") ? idFromNode(node) : ""

  if (Component === Fragment) {
    return <Fragment>{mappedChildren}</Fragment>
  }

  return <Component id={headingId}>{mappedChildren}</Component>
}

export default renderTextNode
