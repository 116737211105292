import { unified } from "unified"
import rehypeParse from "rehype-dom-parse"
import rehypeReact from "rehype-react"
import { createElement, Fragment } from "react"
import rehypeMikan from "@utils/text-parser/mikan-plugin"
import rehypeBreaks from "@utils/text-parser/breaks-plugin"

function buildTextParser() {
  const parser = unified()
    .use(rehypeParse, { fragment: true })
    .use(rehypeBreaks)
    .use(rehypeMikan)
    .use(rehypeReact, { createElement, Fragment })

  return parser
}

const textParser = buildTextParser()

export default textParser
