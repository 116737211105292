import ModalComponent from "@components/Modal/Modal"

const renderModalVideo = ({ videoId, button, title, type = "vimeo" }) => {
  const modal_id = `modal-${videoId}`

  const getVideoProps = () => {
    const vimeoProps = {
      width: 640,
      height: 360,
      className: "embed-responsive-item",
      allowFullScreen: true,
      allow: "autoplay; fullscreen",
      src: `https://player.vimeo.com/video/${videoId}`,
    }

    if (type === "youtube") {
      return {
        ...vimeoProps,
        src: `https://www.youtube.com/embed/${videoId}`,
        allow:
          "accelerometer; autoplay; clipboard-write; encrypted-media; fullscreen; gyroscope; picture-in-picture; web-share",
      }
    }

    return vimeoProps
  }

  return (
    button && (
      <ModalComponent modalId={modal_id} buttonData={button}>
        <div className="embed-responsive embed-responsive-item embed-responsive-16by9">
          <iframe title={title} {...getVideoProps()} />
        </div>
      </ModalComponent>
    )
  )
}

export default renderModalVideo
