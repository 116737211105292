import { withPrefix } from "gatsby"
import { useReducer, useState } from "react"

import { MdClose, MdKeyboardArrowLeft, MdMenu } from "react-icons/md"

import {
  Box,
  Button,
  MobileMenu,
  MobileMenuActions,
  MobileMenuBreadcrumbs,
  MobileMenuContent,
  MobileMenuItem,
  MobileMenuList,
  MobileMenuLogo,
  MobileMenuPortal,
  MobileMenuSectionTitle,
  MobileMenuTrigger,
  SearchProvider,
  useStickyNavigation,
} from "@fastly/consistently-vanilla"

import { LanguageSelector } from "@components/LanguageSelector"

import logo from "../../images/logo.svg"

import { Search } from "../Search"

import * as styles from "./MobileMenu.css"

function navReducer(state: any, action: any) {
  switch (action.type) {
    case "TOGGLE_MENU": {
      return { ...state, open: !state.open }
    }
    case "INCREMENT": {
      return { ...state, activeItem: action.payload, previousItem: state.activeItem, level: state.level + 1 }
    }
    case "DECREMENT": {
      return { ...state, activeItem: action.payload, previousItem: state.activeItem, level: state.level - 1 }
    }
    case "SEARCH_ACTIVE": {
      return { ...state, searchActive: true }
    }
    case "SEARCH_INACTIVE": {
      return { ...state, searchActive: false }
    }
    case "RESET": {
      return { ...state, level: 1, activeItem: action.payload, searchActive: false }
    }
    default:
      return state
  }
}

const filterByNavigationItems = (arrData: any, selectedName: string) => {
  let filteredList = []

  for (const item of arrData) {
    if (item.name === selectedName) {
      filteredList.push(item)
    }

    if (item.navigationItems) {
      const filteredNestedObjects: any = filterByNavigationItems(item.navigationItems, selectedName)
      filteredList = filteredList.concat(filteredNestedObjects)
    }
  }

  return filteredList
}

const MobileMenuWrapper = ({ navData, actions, topNav }: { navData: any; actions: any; topNav: any }) => {
  const { isSticky } = useStickyNavigation(0)
  const [portalRefContainer, setPortalRefContainer] = useState<HTMLElement | null>(null)
  const [state, dispatch] = useReducer(navReducer, {
    level: 1,
    open: false,
    activeItem: navData,
    previousItem: [],
    searchActive: false,
  })

  const { searchActive } = state

  const handleNestedNavigation = (event: any, name: string, action: string) => {
    event.preventDefault()
    const filteredNextActiveItem = filterByNavigationItems(navData.navigationItems, name)[0]
    dispatch({ type: action, payload: filteredNextActiveItem })
  }

  const handleIsOpen = () => {
    dispatch({ type: "TOGGLE_MENU" })

    if (state.open) {
      return dispatch({ type: "RESET", payload: navData })
    }
    return null
  }

  const handleSearchFocus = () => {
    dispatch({ type: "SEARCH_ACTIVE" })
  }

  const handleSearchBlur = () => {
    dispatch({ type: "SEARCH_INACTIVE" })
  }

  return (
    <MobileMenu ref={setPortalRefContainer} isSticky={isSticky} open={state.open} setOpen={handleIsOpen}>
      <MobileMenuLogo src={logo} href={withPrefix("/")} />
      <Box display="flex" gap="sm">
        <LanguageSelector id="language-selector-mobile" />
        <MobileMenuTrigger>
          <span>{state.open ? "Close" : "Menu"}</span>
          {state.open ? <MdClose /> : <MdMenu />}
        </MobileMenuTrigger>
      </Box>
      {/* Portal - Popover */}
      <MobileMenuPortal container={portalRefContainer}>
        <MobileMenuContent nestedLevel={state.level}>
          <div className={styles.searchFocus}>
            <SearchProvider minCharacters={3} groupResults={false} isAlwaysOpen>
              <Search
                showSearchOpenButton={false}
                handleSearchBlur={handleSearchBlur}
                handleSearchFocus={handleSearchFocus}
              />
            </SearchProvider>
          </div>
          {/* Breadcrumbs */}
          {state.level > 1 ? (
            <MobileMenuBreadcrumbs>
              <MdKeyboardArrowLeft />
              <button onClick={() => dispatch({ type: "RESET", payload: navData })}>Main menu</button>
              {state.level > 2 ? (
                <>
                  <span aria-hidden>/</span>
                  <button onClick={(event: any) => handleNestedNavigation(event, state.previousItem.name, "DECREMENT")}>
                    {state.previousItem.name}
                  </button>
                </>
              ) : null}
            </MobileMenuBreadcrumbs>
          ) : null}
          {/* Section Title */}
          {state.activeItem.name ? (
            <MobileMenuSectionTitle
              icon={
                state.activeItem.image?.monochromeIconSmall?.svg?.content ? (
                  <div
                    dangerouslySetInnerHTML={{ __html: state.activeItem.image.monochromeIconSmall.svg.content }}
                    style={{ minWidth: "22px", display: "flex" }}
                  />
                ) : null
              }
            >
              {state.activeItem.name}
            </MobileMenuSectionTitle>
          ) : null}
          {/* List of links */}
          <MobileMenuList>
            {state.activeItem.navigationItems.map((item: any) => {
              if (item?.type === "featured") {
                return (
                  <MobileMenuItem
                    key={item.name}
                    description={item.description}
                    featured
                    href={withPrefix(item.url)}
                    icon={
                      item.image?.monochromeIconSmall?.svg?.content ? (
                        <div
                          dangerouslySetInnerHTML={{ __html: item.image.monochromeIconSmall.svg.content }}
                          style={{ display: "flex", minWidth: "16px" }}
                        />
                      ) : null
                    }
                  >
                    {item.name}
                  </MobileMenuItem>
                )
              }

              if (item.navigationItems && item.navigationItems.length > 0) {
                return (
                  <MobileMenuItem
                    key={item.name}
                    dataName={item.name}
                    description={item.description}
                    icon={
                      item.image?.monochromeIconSmall?.svg?.content ? (
                        <div
                          dangerouslySetInnerHTML={{ __html: item.image.monochromeIconSmall.svg.content }}
                          style={{ display: "flex", minWidth: "16px" }}
                        />
                      ) : null
                    }
                    onClick={(event: any) => handleNestedNavigation(event, item.name, "INCREMENT")}
                  >
                    {item.name}
                  </MobileMenuItem>
                )
              }

              if (!item.navigationItems && item.url !== null) {
                return (
                  <MobileMenuItem key={item.name} description={item.description} href={withPrefix(item.url)}>
                    {item.name}
                  </MobileMenuItem>
                )
              }

              return null
            })}
          </MobileMenuList>
          {/* Call to Actions */}
          {state.level === 1 ? (
            <MobileMenuActions>
              <Box color="white" display="flex" justifyContent="space-between" flexWrap="wrap">
                {topNav?.navigationItems?.map((item) => {
                  if (item.contentful_id === "51K68wPjyWfpk4QS3q8WZM") {
                    return (
                      <a key={item.contentful_id} href={item.url} id="under-attack-button">
                        {item.name}
                      </a>
                    )
                  }

                  return (
                    <Button key={item.name} colorModes="dark" size="compact" url={item.url} variant="text">
                      {item.name}
                    </Button>
                  )
                })}
              </Box>
              {actions?.navigationItems?.map((a, i) => (
                <>
                  {i === 0 ? (
                    <Button key={a.name} url={a.url} colorModes="dark" variant="outline">
                      {a.name}
                    </Button>
                  ) : (
                    <Button key={a.name} url={a.url} colorModes="dark">
                      {a.name}
                    </Button>
                  )}
                </>
              ))}
            </MobileMenuActions>
          ) : null}
          <div className={searchActive ? styles.searchOverlay : ""} />
        </MobileMenuContent>
      </MobileMenuPortal>
    </MobileMenu>
  )
}
export default MobileMenuWrapper
