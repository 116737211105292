import { createContext, useContext } from "react"

const LocalizationContext = createContext({})

export const LocalizationProvider = ({ locale, children }) => {
  return <LocalizationContext.Provider value={{ locale }}>{children}</LocalizationContext.Provider>
}

export const useLocale = () => {
  const { locale } = useContext(LocalizationContext)

  const formatNumber = (value, options = {}) => new Intl.NumberFormat(locale, options).format(value)
  const formatNumberToParts = (value, options = {}) => new Intl.NumberFormat(locale, options).formatToParts(value)

  return { locale, formatNumber, formatNumberToParts }
}
