import { useMemo, useState } from "react"
import {
  Search as ConsistentlySearch,
  highlightSearchString,
  useSearchContext,
  SearchResultGroup,
  ButtonVariant,
} from "@fastly/consistently-vanilla"

import { searchFocus } from "../Header/Header.css"

import { useSwiftype, FetchStatus } from "./use-swiftype"
import { withPrefix } from "gatsby"

type SearchProps = {
  showSearchOpenButton?: boolean
  handleSearchOpen?: VoidFunction
  handleSearchBlur: VoidFunction
  handleSearchFocus?: VoidFunction
  compound?: boolean
}

export const Search = ({
  showSearchOpenButton = true,
  handleSearchOpen,
  handleSearchBlur,
  handleSearchFocus,
}: SearchProps) => {
  const { targetText, isSearchOpen, isDropdownOpen, setSearchResults } = useSearchContext()
  const { results, status } = useSwiftype(targetText)
  const [prevSearchOpen, setPrevSearchOpen] = useState(false)
  const [prevDropdownOpen, setPrevDropdownOpen] = useState(false)

  if (!isSearchOpen && prevSearchOpen !== isSearchOpen && handleSearchBlur) {
    handleSearchBlur()
    setPrevSearchOpen(isSearchOpen)
  }

  if (isSearchOpen && prevSearchOpen !== isSearchOpen && handleSearchOpen) {
    handleSearchOpen()
    setPrevSearchOpen(isSearchOpen)
  }

  if (!showSearchOpenButton) {
    if (isDropdownOpen && prevDropdownOpen !== isDropdownOpen && handleSearchFocus) {
      handleSearchFocus()
      setPrevDropdownOpen(isDropdownOpen)
    }
    if (!isDropdownOpen && prevDropdownOpen !== isDropdownOpen && handleSearchBlur) {
      handleSearchBlur()
      setPrevDropdownOpen(isDropdownOpen)
    }
  }

  useMemo(() => {
    const searchResults: SearchResultGroup[] = [
      {
        title: "All Results",
        allowVisibilityToggle: false,
        showAllResults: true,
        results: [],
      },
    ]

    if (status === FetchStatus.Fulfilled && results && results.length > 0) {
      searchResults[0].results = results.map((page) => ({
        href: withPrefix(page.url),
        title: highlightSearchString(targetText, page.title),
        description: highlightSearchString(targetText, page.body),
        alwaysVisible: true,
        id: page.id,
      }))
    } else {
      searchResults[0].results = []
    }
    setSearchResults(searchResults)
  }, [results, status, targetText])

  return (
    <div className={searchFocus[isSearchOpen && showSearchOpenButton ? "open" : "closed"]}>
      <ConsistentlySearch
        showSearchOpenButton={showSearchOpenButton}
        showSearchCloseButton={isSearchOpen && showSearchOpenButton}
        queryStatusPending={status === FetchStatus.Pending}
        dropdownDisplayOptions={{
          showGroupTitle: false,
          showDescription: false,
          showContentType: false,
        }}
        buttonVariant={ButtonVariant.MagnifyingGlass}
      />
    </div>
  )
}
