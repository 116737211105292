import qs from "query-string"
import { useTheme } from "styled-components"
import { fastlyIoUrl } from "@utils/fastly-io"
import { ImgHTMLAttributes } from "react"
import { system } from "styled-system"

type FastlyImg = {
  src?: string
  /**
   * for responsive images (named breakpoint object or array syntax)
   * a width for each breakpoint is required
   */
  params?: string | IoParams | IoParams[] | Record<string, IoParams>
  alt?: string
  loading?: "lazy" | "eager"
  contentKey?: string
  className?: string
  /** if fixedWidth is true then the width provided to params is also the set display width */
  fixedWidth?: boolean
  dpr?: boolean
  elementtiming?: string
  width?: string
  height?: string
}

type IoParams = {
  auto?: "webp" | "avif"
  "bg-color"?: string
  blur?: string | number
  brightness?: string | number
  canvas?: string
  contrast?: string | number
  crop?: string
  disable?: "upscale"
  dpr?: number
  enable?: "upscale"
  fit?: "bounds" | "cover" | "crop"
  format?:
    | "gif"
    | "png"
    | "png8"
    | "jpg"
    | "jpeg"
    | "pjpg"
    | "pjepg"
    | "bjpg"
    | "bjpeg"
    | "webp"
    | "webpll"
    | "webply"
    | "mp4"
    | "avif"
  frame?: number
  height?: string | number
  level?: string | number
  optimize?: "low" | "medium" | "high"
  orient?: "r" | "l" | "h" | "v" | "hv" | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8
  pad?: "top" | "right" | "bottom" | "left"
  precrop?: string
  profile?: "baseline" | "main" | "high"
  quality?: string | number
  "resize-filter"?: "nearest" | "bilinear" | "linear" | "bicubic" | "cubic" | "lanczos" | "lanczos2" | "lanczos3"
  saturation?: string | number
  sharpen?: string
  trim?: string
  width?: number | string
}

const MAX_DPR = 2

type Props = {
  src?: string
  /**
   * for responsive images (named breakpoint object or array syntax)
   * a width for each breakpoint is required
   */
  params?: string | IoParams | IoParams[] | Record<string, IoParams>
  alt?: string
  loading?: "lazy" | "eager"
  contentKey?: string
  className?: string
  /** if fixedWidth is true then the width provided to params is also the set display width */
  fixedWidth?: boolean
  dpr?: boolean
  elementtiming?: string
  width?: string
  height?: string
}

const paramsSystem = system({
  params: {
    property: "imageRendering",
    transform: (p: IoParams) => ({
      urlParams: `?${qs.stringify(p)} ${p.width}w`,
      width: `${p.width}px`,
    }),
  },
})

export const FastlyImage = ({
  src = "",
  params = "",
  alt = "",
  contentKey = "",
  loading = "lazy",
  className,
  elementtiming,
  width,
  height,
  dpr = false,
}: Props): JSX.Element => {
  const theme = useTheme()
  const imgProps: ImgHTMLAttributes<HTMLImageElement> = {}

  const hasResponsiveParams =
    (Array.isArray(params) && params.every((paramSet) => paramSet.width)) ||
    (typeof params === "object" && Object.values(params).every((paramSet) => paramSet.width))

  if (hasResponsiveParams) {
    // Responsive array or object and each paramSet has a set width
    // parse responsive array or object
    const { imageRendering: fallback, ...mediaConditions } = paramsSystem({ params, theme })
    // set srcset and sizes
    imgProps.srcSet = [fallback, ...Object.values(mediaConditions).map((m: any) => m.imageRendering)]
      .map((p) => fastlyIoUrl(src, p.urlParams))
      .join(", ")
    imgProps.sizes = Object.entries(mediaConditions)
      .map(
        ([mediaQuery, value]) =>
          `${mediaQuery.replace("@media screen and ", "")} ${(value as any).imageRendering.width}`
      )
      .reverse()
      .join(", ")
    imgProps.sizes += `, ${fallback.width}`
  } else if (typeof params === "object") {
    // Single object tream at a single paramSet
    imgProps.src = fastlyIoUrl(src, `?${qs.stringify(params)}`)

    if (dpr) {
      imgProps.srcSet = [...Array(MAX_DPR)]
        .map((_, i) => {
          const densityValue = i + 1
          return densityValue > 1
            ? `${imgProps.src}&dpr=${densityValue} ${densityValue}x`
            : `${imgProps.src} ${densityValue}x`
        })
        .join(", ")
    }
  } else if (typeof params === "string") {
    // String treat as already url safe paramSet
    imgProps.src = fastlyIoUrl(src, params)
  } else {
    throw new Error(`Unexpected params prop in FastlyImage: ${JSON.stringify(params)}`)
  }

  return (
    <img
      {...imgProps}
      alt={alt}
      loading={loading}
      className={className}
      key={contentKey}
      elementtiming={elementtiming}
      width={width}
      height={height}
    />
  )
}
