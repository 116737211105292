import { Layout } from "@components/layout"
import Loadable from "@loadable/component"

import "@fastly/consistently/dist/assets/css/colors.css"
import "@fastly/consistently/dist/assets/css/tokens.css"
import "@fastly/consistently/dist/assets/css/typography.css"
import "@fastly/consistently/es-rebond-grotesque.css"

import "@fastly/consistently-vanilla/css"

if (process.env.GATSBY_QUERY_LOCALE === "ja") {
  require(`./src/assets/scss/rebrand-${process.env.GATSBY_QUERY_LOCALE}.scss`)
} else if (process.env.GATSBY_QUERY_LOCALE === "de") {
  require(`./src/assets/scss/rebrand-${process.env.GATSBY_QUERY_LOCALE}.scss`)
} else {
  require(`./src/assets/scss/rebrand.scss`)
}

export const wrapPageElement = ({ element, props }) => {
  const stagingToolBarIsEnabled =
    Object.keys(props.pageContext).length > 0 && props.pageContext?.featureFlags
      ? props.pageContext.featureFlags["staging-tool-bar"]?.isEnabled
      : false
  const StagingToolBar =
    stagingToolBarIsEnabled && Loadable(() => import("./src/components/StagingToolBar/StagingToolBar"))

  return (
    <Layout {...props}>
      {element}
      {stagingToolBarIsEnabled && (
        <StagingToolBar contentfulId={props?.data?.page?.contentful_id ?? props?.pageContext?.contentful_id} />
      )}
    </Layout>
  )
}
