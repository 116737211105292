import styled from "styled-components"
import { Stack, Box } from "@fastly/consistently"

const BaseCard = styled(Stack)`
  border-radius: var(--size-radius-lg);
  background-color: var(--white);
  position: relative;
  justify-content: flex-start;
  padding: var(--size-space-md) var(--size-space-md) var(--size-space-lg) var(--size-space-md);
  box-shadow: 0px 16px 48px rgba(0, 0, 0, 0.06);
  transition: box-shadow 0.4s;
  width: 100%;
  height: auto;

  &:hover {
    box-shadow: 0 16px 24px rgba(0, 0, 0, 0.15);
  }

  &:focus-within {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12);
  }

  & p:empty {
    display: none;
  }
`

const BaseCardHeader = styled((props) => <Stack {...props} />)`
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: flex-start;

  // border bottom
  margin-left: calc(var(--size-space-md) * -1);
  margin-right: calc(var(--size-space-md) * -1);
  padding: 0 var(--size-space-sm) var(--size-space-sm) var(--size-space-sm);
  border-bottom: 1px solid var(--grey-30);
`

const BaseCardBody = styled((props) => <Box {...props} />)`
  h5,
  p {
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    white-space: normal;
    overflow-wrap: break-word;
  }
  h5 {
    -webkit-line-clamp: 2;
  }

  p {
    -webkit-line-clamp: 3;
    color: var(--grey-70);
  }
`

const BaseCardFooter = styled((props) => <Box {...props} />)`
  justify-self: flex-end;
  margin-top: auto;

  & a {
    &::after {
      content: "";
      position: absolute;
      inset: 0;
      display: block;
    }
  }
`

export { BaseCard, BaseCardHeader, BaseCardBody, BaseCardFooter }
