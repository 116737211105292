import BackgroundVideo from "@components/BackgroundVideo"
import { FastlyImage } from "@components/FastlyImage"
import { LocalizedRTBodyCopy } from "@components/Mikan"
import { BaseTable } from "@components/Table/BaseTable"
import { BLOCKS, INLINES, MARKS } from "@contentful/rich-text-types"
import { Box, CodeBlock, Button as ConsistentlyButton, Stack } from "@fastly/consistently"
import { H1, H2, H3, H4, H5, H6 } from "@fastly/consistently-vanilla"
import { useTextParser } from "@hooks/use-text-parser"
import applyPrefix from "@utils/applyPrefix"
import { fastlyIoUrl } from "@utils/fastly-io"
import { Script } from "gatsby"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import PropTypes from "prop-types"
import { Fragment } from "react"
import styled from "styled-components"
import Icon from "../Icon/Icon"
import { AnalystCallOut as WebBasedResourcesAnalystCallOut } from "../WebBasedResources/AnalystCallOut"
import { CustomerQuote as WebBasedResourcesCustomerQuote } from "../WebBasedResources/CustomerQuote"
import { KeyStat as WebBasedResourcesKeyStat } from "../WebBasedResources/KeyStat"
import { Spotlight as WebBasedResourcesSpotlight } from "../WebBasedResources/Spotlight"
import renderInlineVideo from "./renderInlineVideo"
import renderModalVideo from "./renderModalVideo"
import renderTextNode from "./renderTextNode"
import CollapsedList from "../CollapsedList/CollapsedList"

const CodeWrapper = styled.code`
  display: inline-flex;
  word-break: break-all;
`

const HorizontalRule = styled.hr`
  width: "100%";
  height: "1px";
  margin: 2em 0;

  // Overriding an errant global class
  &:last-of-type {
    margin: 2em 0;
  }
`

const HyperlinkWrapper = styled.a`
  word-break: break-word;
`

const ImgListItem = styled.li`
  position: relative;
  &::before {
    content: ${(props) => props.imgUrl};
    position: absolute;
    left: -36px;
    top: 2px;
  }
`

const NoBulletUnorderedList = styled.ul`
  list-style: none;
  padding-left: 36px;
`

const WebBasedTwoColumn = ({ data }) => {
  return (
    <Stack direction={["vertical", "horizontal"]}>
      <Box flexBasis={{ md: "50%" }}>
        <RichText body={data.leftColumn} />
      </Box>
      <Box flexBasis={{ md: "50%" }}>
        <RichText body={data.rightColumn} />
      </Box>
    </Stack>
  )
}

const RichText = ({ body, renderOptions = {}, mikan }) => {
  const textParser = useTextParser()

  const options = {
    renderMark: {
      [MARKS.CODE]: (text) => {
        return <CodeWrapper>{text}</CodeWrapper>
      },
      // Wrap text with marks but leave it as string for further parsing by text parser
      // As marks are resolved before nodes
      [MARKS.BOLD]: (text) => `<b>${text}</b>`,
      [MARKS.ITALIC]: (text) => `<i>${text}</i>`,
      [MARKS.UNDERLINE]: (text) => `<u>${text}</u>`,
    },
    renderNode: {
      [BLOCKS.UL_LIST]: (node, children) => {
        if (Number.isInteger(renderOptions.collapsedListMax) && renderOptions.collapsedListButtonText)
          return (
            <CollapsedList
              StyledList={NoBulletUnorderedList}
              max={renderOptions.collapsedListMax}
              buttonCopyGroup={renderOptions.collapsedListButtonText}
              onClickOverride={renderOptions.onClickOverride}
            >
              {children}
            </CollapsedList>
          )
        if (!renderOptions.listItemImage) return <ul>{children}</ul>
        return <NoBulletUnorderedList>{children}</NoBulletUnorderedList>
      },
      [BLOCKS.LIST_ITEM]: (node, children) => {
        if (!renderOptions.listItemImage) return <li>{children}</li>
        return <ImgListItem imgUrl={`url(${fastlyIoUrl(renderOptions.listItemImage)})`}>{children}</ImgListItem>
      },
      [BLOCKS.HEADING_1]: (node, children) => {
        return renderTextNode(node, children, H1, textParser)
      },
      [BLOCKS.HEADING_2]: (node, children) => {
        return renderTextNode(node, children, H2, textParser)
      },
      [BLOCKS.HEADING_3]: (node, children) => {
        return renderTextNode(node, children, H3, textParser)
      },
      [BLOCKS.HEADING_4]: (node, children) => {
        return renderTextNode(node, children, H4, textParser)
      },
      [BLOCKS.HEADING_5]: (node, children) => {
        return renderTextNode(node, children, H5, textParser)
      },
      [BLOCKS.HEADING_6]: (node, children) => {
        return renderTextNode(node, children, H6, textParser)
      },
      [BLOCKS.PARAGRAPH]: (node, children) => {
        if (children && children.length === 0) {
          return null
        }

        if (children && children.length === 1 && children[0] === "") {
          return null
        }

        return renderTextNode(node, children, LocalizedRTBodyCopy, textParser, mikan)
      },
      [BLOCKS.TABLE]: (node, children) => (
        <Box overflowX="auto">
          <BaseTable>
            <tbody>{children}</tbody>
          </BaseTable>
        </Box>
      ),
      [BLOCKS.TABLE_CELL]: (node, children) => {
        const tableCellValue = node?.content[0]?.content[0]?.value?.slice(1, -1)

        const tableCellIconList = ["check", "cross", "full", "partial", "none"]

        if (tableCellIconList?.includes(tableCellValue)) {
          return (
            <td>
              <Box margin="0 auto" data-icon={`${tableCellValue}`}></Box>
            </td>
          )
        }

        return <td>{children}</td>
      },
      [INLINES.HYPERLINK]: (node, children) => {
        let href = applyPrefix(node.data.uri)
        return (
          <HyperlinkWrapper href={href} {...(renderOptions.targetBlank ? { target: "_blank" } : {})}>
            {renderTextNode(node, children, Fragment, textParser)}
          </HyperlinkWrapper>
        )
      },
      [INLINES.EMBEDDED_ENTRY]: (node) => {
        const target = node.data?.target
        const type = target?.__typename

        if (!type) {
          return <></>
        }

        switch (type) {
          case "ContentfulMicrocopy":
            return <>{target.text}</>
          default:
            console.warn("unknown INLINES.EMBEDDED_ENTRY", node)
            return null
        }
      },
      [BLOCKS.HR]: () => {
        return <HorizontalRule />
      },
      [BLOCKS.EMBEDDED_ENTRY]: (node) => {
        const target = node.data?.target
        const type = target?.__typename

        if (!type) {
          return <></>
        }

        switch (type) {
          case "ContentfulWebBasedResourcesTwoColumnLayout":
            return <WebBasedTwoColumn data={target} />

          case "ContentfulWebBasedResourcesCustomerQuote":
            return <WebBasedResourcesCustomerQuote data={target} />

          case "ContentfulWebBasedResourcesKeyStat":
            return <WebBasedResourcesKeyStat data={target} />

          case "ContentfulWebBasedResourcesSpotlight":
            return <WebBasedResourcesSpotlight data={target} />

          case "ContentfulWebBasedResourcesAnalystCallOut":
            return <WebBasedResourcesAnalystCallOut data={target} />

          //@FIXME  can we use Loadable components directly here?
          case "ContentfulIcon":
            return (
              <div className="rt-icon">
                <Icon {...target} />
              </div>
            )

          case "ContentfulHtmlSnippet": {
            const nodeContent = target.content.childMarkdownRemark.html
            const jsbin = nodeContent.includes("jsbin.com") ? (
              <Script src="https://static.jsbin.com/js/embed.min.js?4.1.4"></Script>
            ) : null
            return (
              <>
                {jsbin}
                {textParser.process(nodeContent)}
              </>
            )
          }

          case "ContentfulCodeBlock":
            return (
              <CodeBlock language={target.language} theme={target.theme}>
                {target.code.code}
              </CodeBlock>
            )

          case "ContentfulVclFiddle":
            // Embed script uses Gatsby Script for de-duping
            // Fiddle data model needs to be a regular script tag,
            // Probably because of loaading strategy - it needs to be on the page first
            return (
              <>
                <Script defer src="https://fiddle.fastlydemo.net/embed.js" />
                <script type="application/json+fiddle">{target.fiddleObject.fiddleObject}</script>
              </>
            )

          case "ContentfulVideoEmbed":
            // @TODO accept title in content model?
            if (target.embedType === "modal") {
              return renderModalVideo(target)
            } else {
              return renderInlineVideo(target)
            }

          case "ContentfulBackgroundVideo":
            return <BackgroundVideo target={target} />

          case "ContentfulButton": {
            const hasExpectedFields = target.buttonText && target.variant && target.url
            if (!hasExpectedFields) return null
            const href = applyPrefix(target.url)

            const customAttr = target.customAttributeJson
              ? JSON.parse(target.customAttributeJson?.internal.content)
              : {}
            return (
              <ConsistentlyButton
                variant={target.variant}
                url={href}
                icon={target.iconString}
                iconDirection={target.iconDirection}
                color={target.colorValue}
                textColor={target.textColorValue}
                customAttributes={customAttr}
              >
                {textParser.process(target.buttonText)}
              </ConsistentlyButton>
            )
          }

          case "ContentfulLinkedImage":
            return (
              <a href={target.url}>
                <img src={target.image.file.url} alt={target?.title} />
              </a>
            )
          default:
            console.warn("unknown INLINES.EMBEDDED_ENTRY", node)
            return null
        }
      },
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        if (node.data.target == null || node.data.target.sys?.type === "Link") {
          return <></>
        }

        const { title, file } = node.data.target

        if (renderOptions.wrapImage) {
          return (
            <p>
              <FastlyImage src={file.url} params={{ auto: "avif" }} alt={title} />
            </p>
          )
        }

        return <FastlyImage src={file.url} params={{ auto: "avif" }} alt={title} />
      },
    },
  }
  if (body) {
    return <>{renderRichText(body, options)}</>
  } else {
    return <></>
  }
}

RichText.propTypes = {
  body: PropTypes.any,
  renderOptions: PropTypes.shape({
    wrapImage: PropTypes.bool,
    textParser: PropTypes.shape({
      forceTextJustify: PropTypes.bool,
      forceMikan: PropTypes.bool,
      disableMikan: PropTypes.bool,
      displayInline: PropTypes.bool,
    }),
    targetBlank: PropTypes.bool,
    listItemImage: PropTypes.string,
    collapsedListMax: PropTypes.number,
    collapsedListButtonText: PropTypes.string,
    onClickOverride: PropTypes.func,
  }),
}

export default RichText
