import { withPrefix } from "gatsby"
import { Box, Text, Button as ConsistentlyButton } from "@fastly/consistently"
import styled, { css } from "styled-components"
import { FastlyImage } from "@components/FastlyImage"
import { MikanSpan } from "@components/Mikan"
import useDataLayer from "@hooks/use-data-layer"

const CustomerQuoteWrapper = styled((props) => <Box {...props} />)`
  ${({ theme }) => css`
    padding: ${theme.space.lg};
    margin: ${theme.space["2xl"]} 0;
    position: relative;
    background-color: var(--white);
    border-radius: ${({ theme }) => theme.radii.md};
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12); // none of shadow tokens align with design requirements

    &::after {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      left: -3.5px;
      top: 0;
      background-image: linear-gradient(var(--blue), var(--green-light));
      border-radius: ${({ theme }) => theme.radii.md};
      z-index: -1;
    }

    img {
      max-height: 48px;
      margin: 0;
    }

    img,
    p.quote {
      margin-bottom: ${theme.space.md};
    }

    p.name {
      margin-bottom: ${theme.space[1]};
    }
    p:last-of-type {
      margin-bottom: 0;
    }
  `}
`

export const CustomerQuote = ({ data }) => {
  const { sendDataLayer } = useDataLayer()
  const { logo, quote, jobTitle, customerName, linkText, linkUrl, dataLayer } = data

  return (
    <CustomerQuoteWrapper>
      {logo ? (
        <FastlyImage
          src={logo?.file?.url}
          loading="eager"
          params={{ height: 23, width: "auto", fit: "contain", auto: "avif" }}
          alt={logo?.title}
          dpr={logo?.file?.url?.includes(".svg") ? false : true}
        />
      ) : null}
      <div>
        <Text as="p" variant="xl" alignText="left" className="quote">
          <MikanSpan text={quote.internal.content} />
        </Text>
        <Text as="p" variant="lg" alignText="left" className="name">
          <MikanSpan text={customerName} />
        </Text>
        <Text as="p" variant="md" alignText="left" className="jobTitle">
          <MikanSpan text={jobTitle} />
        </Text>
        {linkText && linkUrl ? (
          <ConsistentlyButton
            variant="link"
            icon="arrow"
            url={withPrefix(linkUrl)}
            onClick={dataLayer ? () => sendDataLayer(dataLayer) : null}
          >
            <MikanSpan text={linkText} />
          </ConsistentlyButton>
        ) : null}
      </div>
    </CustomerQuoteWrapper>
  )
}
