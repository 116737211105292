import { Text, Button as ConsistentlyButton } from "@fastly/consistently"
import { MikanSpan } from "@components/Mikan"
import { BaseCard } from "@components/Card/Card"
import { FastlyImage } from "@components/FastlyImage"
import styled from "styled-components"

const BaseCardImage = styled.div``
const BaseCardWrapper = styled(BaseCard)`
  max-width: 400px;
  margin: var(--size-space-xl) auto;
  border: double 0.5px transparent;
  padding: 0.125rem; /* 2px */
  border-radius: var(--size-radius-lg);
  background-image: linear-gradient(white, white), linear-gradient(to top, var(--color-blue-50), var(--color-green-50));
  background-origin: border-box;
  background-clip: content-box, border-box;

  > div {
    padding: 0 var(--size-space-xl);

    &:last-of-type {
      padding-bottom: var(--size-space-xl);
    }
  }

  ${BaseCardImage} {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: var(--size-space-xl) 0;

    img {
      max-width: 10rem;
    }
  }
`

export const AnalystCallOut = ({ data }) => {
  const { title, image, body, buttonText, buttonUrl } = data

  return (
    <BaseCardWrapper key={data.title} height={{ _: "300px" }} gap="var(--size-space-md)">
      <BaseCardImage>
        <FastlyImage
          src={image?.file.url}
          loading="eager"
          params={{ _: { auto: "avif" } }}
          alt={image?.title}
          dpr={image?.file.url.includes(".svg") ? false : true}
        />
      </BaseCardImage>
      <div>
        <Text as="h4" variant="display2xl">
          <MikanSpan text={title}></MikanSpan>
        </Text>
        <Text as="p" variant="md">
          <MikanSpan text={body.internal.content} />
        </Text>
      </div>
      <div>
        <ConsistentlyButton url={buttonUrl} variant="link" icon="arrow">
          {buttonText}
        </ConsistentlyButton>
      </div>
    </BaseCardWrapper>
  )
}
