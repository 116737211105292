const renderInlineVideo = ({ videoId, type = "vimeo" }) => {
  const getVideoProps = () => {
    const vimeoProps = {
      width: 640,
      height: 360,
      className: "embed-responsive-item",
      allowFullScreen: true,
      allow: "autoplay; fullscreen",
      title: "Vimeo embed",
      src: `https://player.vimeo.com/video/${videoId}`,
    }

    if (type === "youtube") {
      return {
        ...vimeoProps,
        title: "Youtube embed",
        src: `https://www.youtube.com/embed/${videoId}`,
        allow:
          "accelerometer; autoplay; clipboard-write; encrypted-media; fullscreen; gyroscope; picture-in-picture; web-share",
      }
    }

    return vimeoProps
  }

  // eslint-disable-next-line jsx-a11y/iframe-has-title
  return <iframe {...getVideoProps()} />
}

export default renderInlineVideo
