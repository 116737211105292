import * as iconMap from "../icon-map"

const Icon = ({ name, height = "50px", width = "50px", color = "var(--red)", accessibilityTitle }) => {
  const ProxyIcon = iconMap[name]

  return (
    <ProxyIcon
      height={height}
      width={width}
      color={color}
      hide={accessibilityTitle ? "false" : "true"}
      title={accessibilityTitle}
      titleId={accessibilityTitle && name}
      fallback={<span style={{ width, height }} />}
    />
  )
}

export default Icon
