import slugify from "slugify"

const idFromNode = (node) => {
  // node may be a string or an object
  const nodeString = node.content[0] === undefined ? node : node.content[0].value
  return typeof nodeString === "string"
    ? slugify(nodeString, { lower: true, remove: /[.:,?]/g }).replace(/'/g, "-")
    : null
}

export default idFromNode
