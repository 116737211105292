import styled from "styled-components"

export const BaseTable = styled.table`
  border-spacing: 0;

  th,
  td {
    padding-block: ${({ theme }) => theme.space.sm};
    padding-inline: ${({ theme }) => theme.space["2xs"]};

    @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
      padding-block: ${({ theme }) => theme.space.md};
      padding-inline: ${({ theme }) => theme.space.lg};
    }

    p {
      margin-bottom: 0;
    }
  }

  th {
    font-weight: 700;
    border-bottom: 1px solid var(--grey-60);
  }

  td {
    border-bottom: 1px solid var(--grey-40);
    min-width: 8rem;
  }

  td > div[data-icon="check"] {
    height: 2rem;
    width: 2rem;
    mask: url("/g-static/icons/icon-check.svg");
    mask-size: cover;
    background-color: var(--green-dark);
  }

  td > div[data-icon="cross"] {
    height: 1.5rem;
    width: 1.5rem;
    mask: url("/g-static/icons/icon-cross.svg");
    mask-size: cover;
    background-color: var(--color-grey-90);
  }

  td > div[data-icon="full"] {
    height: 1.5rem;
    width: 1.5rem;
    mask: url("/g-static/icons/fully-supported.svg");
    mask-size: cover;
    background-color: #2f9527;
  }

  td > div[data-icon="partial"] {
    height: 1.5rem;
    width: 1.5rem;
    mask: url("/g-static/icons/partially-supported.svg");
    mask-size: cover;
    background-color: #cc8600;
  }

  td > div[data-icon="none"] {
    height: 1.5rem;
    width: 1.5rem;
    mask: url("/g-static/icons/not-supported.svg");
    mask-size: cover;
    background-color: #bd140a;
  }
`
